@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;700&display=swap);
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.header {
  background-color: #ffffff;
  display: flex;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

code {
  background-color: #f1f1f1;
  color: crimson;
}

table-code-header{
  background-color: white;
  color: rgb(18, 18, 18);
  font-size: 16px;
  font-weight: normal;
}

table-code-parameter-header{
  background-color: white;
  color: rgb(18, 18, 18);
  font-size: 16px;
  font-weight: bold;
}

body {
  font-size: 14px;
}

.title-header {
  color: #02416D;
  font-weight: bold;
  margin-left: 20px;
  font-size: 25px;
}

.title {
  color: #02416D;
  font-weight: bold;
  font-size: 24px;
}

.title-sub {
  color: #454545;
  font-weight: bold;
  font-size: 20px;
}

.title-sub2 {
  color: #454545;
  font-weight: bold;
  font-size: 16px;
}

.title-sub3 {
  color: #02416D;
  font-weight: bold;
  font-size: 18px;
}

.font-bold {
  color: #454545;
  font-weight: bold;
}

.font-side {
  font-size: 14px;
}

.sider-unifiedapi {
  max-width: 170px !important;
  min-width: 170px !important;
  width: 170px !important;
}

a.ant-anchor-link-title{
  padding: 1px;
  font-size: 13px;
}

.sider-unifiedapi a.ant-anchor-link-title{
  padding: 1px;
  font-size: 12px;
}

*{
  font-family: 'Noto Sans Thai', sans-serif;
}

.font-object-parameter {
  color: #454545;
  font-weight: bold;
  background-color: white;
}

.css-newline {
  white-space: pre-wrap;
}

pre.line-numbers.code-api.language-json{
  font-size: 12px;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
